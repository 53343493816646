<template>
    <div>
        <NoButtonModal :open="deleteModal">
            <button class="close-corner-x" type="button" @click.prevent="deleteModal = false">
                <span class="material-icons-outlined dark-text">close</span>
            </button>
            <div class="">
                <h3>Delete User</h3>
                <p>Are you sure you want to delete this user? This action cannot be reversed.</p>
                <button @click="deleteModal = false" class="secondary">Cancel</button>
                <button @click="deleteUser" class="primary">Confirm</button>
            </div>
        </NoButtonModal>
        <!--        This will be the user profile image component-->
        <div class="card-block user-profile-header flex">
            <!--            <div class="profile-img">-->
            <!-- <img src="" alt="profile" /> -->
            <div>
                <UploadOverlay
                    kind="user_profile_photo"
                    alt=" "
                    :urlToUpload="profilePhotoUploadUrl"
                    :imgUrl="profilePhotoDownloadUrl"
                    @returnApiData="handleNewUpload"
                />
            </div>
            <!--            </div>-->
            <div class="profile-details flex wrap">
                <div class="fullwidth flex center bottom-15">
                    <h1 class="right-5 bottom-0">{{ userName }}</h1>
                    <!--<ejs-dropdownbutton
                        class="margin-0 user-dropdown"
                        :items="items"
                        :select="handleDropdownSelection"
                        v-if="canDelete"
                    ></ejs-dropdownbutton>-->
                </div>
                <div>
                    <div class="single-setting">
                        <span class="toggle-label">Status</span>
                        <Toggle
                            id="userProfileStatus"
                            class="toggle-switch"
                            :value="active"
                            @input="updateActive"
                            :disabled="userId == $store.state.user.id || (role !== 'Admin' && role !== 'Super Admin') ? true : false"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :label="active ? 'Active' : 'Inactive'"
                        />
                        <!-- <Checkbox
                            :value="active"
                            @input="updateActive"
                            id="active"
                            name="active"
                            :label="active ? 'Active' : 'Inactive'"
                            :disabled="!isEditMode"
                        /> -->
                    </div>
                    <div class="single-setting" style="display: none">
                        <span class="toggle-label">2FA</span>
                        <Toggle
                            :value="use2fa"
                            @input="update2fa"
                            id="use2fa"
                            name="use2fa"
                            class="toggle-switch"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :label="use2fa ? 'Yes' : 'No'"
                        />
                        <!-- <Checkbox
                            :value="use2fa"
                            @input="update2fa"
                            id="use2fa"
                            name="use2fa"
                            :label="use2fa ? 'Yes' : 'No'"
                            :disabled="!isEditMode"
                        /> -->
                    </div>
                    <div class="single-setting" v-if="unlockButton">
                        <div>
                            {{ userName }} has had too many bad login attempts in the last 24 hours and their account is
                            locked.
                            <button
                                class="primary"
                                @click="$emit('resetLogin')"
                                id="the_user_header_unlock_login_button"
                            >
                                Unlock Login
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="single-setting office flex center">
                        <span class="material-icons-outlined right-5">holiday_village</span>
                        <div v-if="offices.length > 1">
                            <Dropdown
                                :value="primaryOfficeId"
                                @input="updatePrimaryOffice"
                                id="primaryOffice"
                                name="primaryOffice"
                                label="Primary Office"
                                :options="offices"
                                :disabled="role !== 'Admin' ? true : false"
                                disable-empty
                                :readonly="!isEditMode"
                            />
                        </div>
                        <div v-else>
                            {{ officeName }}
                        </div>
                    </div>
                    <!-- RESET PASSWORD FOR ADMIN LOGGED INTO ANOTHER USER -->
                    <div class="single-setting reset-password" v-if="showResetLink">
                        <BaseModal>
                            <template #control="{ open }">
                                <button @click="open" class="no-bg text-button">
                                    <span class="material-icons-outlined">lock</span> Send Reset Password Link
                                </button>
                            </template>
                            <template v-if="active" #content="{ close }">
                                <div>
                                    <h3 class="top-0">Change Password</h3>
                                    <div class="bottom-20">
                                        Click confirm to send a link to your email to change your password.
                                    </div>
                                    <div class="align-right top-20">
                                        <button @click="close" class="secondary">Cancel</button>
                                        <button @click="resetPassword(close)" class="primary">Confirm</button>
                                    </div>
                                </div>
                            </template>
                            <template v-else #content="{ close }">
                                <div>
                                    <h3 class="top-0">Change Password</h3>
                                    <div class="bottom-20">User status should be active to reset Password.</div>
                                    <div class="align-right top-20">
                                        <button @click="close" class="primary">Ok</button>
                                    </div>
                                </div>
                            </template>
                        </BaseModal>
                    </div>
                    <!-- MY SETTINGS LINK - LOGGEDINTO THEIR OWN ACCOUNT -->
                    <div class="single-setting user-settings">
                        <router-link v-if="user.id == userId" :to="{ name: 'UserSettings' }" class="dark-text">
                            <span class="material-icons-outlined">settings</span> My Settings
                        </router-link>
                    </div>
                </div>

                <!--                <div>-->

                <!--                    <BaseModal v-if="canDelete" class="delete">-->
                <!--                        <template #control="{ open }">-->
                <!--                            <button @click="open" class="no-bg"><b>✕</b>&nbsp; Delete User</button>-->
                <!--                        </template>-->
                <!--                        <template #content="{ close }">-->
                <!--                            <div class="">-->
                <!--                                <h3>Delete User</h3>-->
                <!--                                <p>Are you sure you want to delete this user? This action cannot be reversed.</p>-->
                <!--                                <button @click="close" class="secondary">Cancel</button>-->
                <!--                                <button @click="deleteUser(close)" class="primary">Confirm</button>-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </BaseModal>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import { mapState } from 'vuex';
    import { auth, file, users } from '@/util/apiRequests';
    import BaseModal from '@/components/general/modals/BaseModal';
    import UploadOverlay from '@/components/general/Upload/UploadOverlay';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons';
    import dayjs from 'dayjs';

    Vue.use(DropDownButtonPlugin);

    export default {
        name: 'UserDetailHeader',
        components: { NoButtonModal, BaseModal, UploadOverlay },
        props: {
            userId: {
                type: [String, Number],
                required: true,
            },
            userName: {
                type: String,
                required: true,
            },
            companyId: {
                type: [String, Number],
                required: true,
            },
            offices: {
                type: Array,
                required: true,
            },
            isEditMode: {
                type: Boolean,
                required: true,
            },
            role: {
                type: String,
                required: true,
            },
            unlockButton: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                deleteModal: false,
                active: false,
                use2fa: false,
                primaryOfficeId: 0,
                profilePhotoId: null,
                rng: '1234',
                items: [
                    {
                        id: `1_delete`,
                        text: 'Delete User',
                    },
                ],
            };
        },
        computed: {
            ...mapState({
                user: 'user',
            }),
            canDelete() {
                return this.pageMeta?.permissions?.delete && this.user?.id != this.userId;
            },
            profilePhotoUploadUrl() {
                if (!this.profilePhotoId) {
                    return `/users/${this.userId}/upload-profile-photo`;
                }

                return `/users/${this.userId}/upload-profile-photo?fileId=${this.profilePhotoId}`;
            },
            profilePhotoDownloadUrl() {
                if (!this.profilePhotoId) {
                    return '';
                }
                return file.getProfilePhoto(this.profilePhotoId);
            },
            showResetLink() {
                //you the same person? bye
                if (this.$route.params.userId == this.$store.state.user.id) {
                    return false;
                }
                //you not an admin? bye
                if (this.role !== 'Admin' && this.role !== 'Super Admin') {
                    return false;
                }
                return true;
            },
            officeName() {
                if (this.offices.length > 0) {
                    return this.offices[0].text;
                } else {
                    return '';
                }
            },
        },
        methods: {
            handleDropdownSelection(args) {
                const [id, action] = args.element.id.split('_');

                switch (action) {
                    case 'delete':
                        this.deleteModal = true;
                        break;
                }
            },
            async init() {
                try {
                    const res = await this.$api.get(users.getHeaderDetails(this.userId));

                    if (res.status === 404) {
                        return;
                    }

                    const { isActive, use2fa, primaryOfficeId, profilePhotoId } = res.data;

                    this.active = Boolean(isActive);
                    this.use2fa = Boolean(use2fa);
                    this.primaryOfficeId = primaryOfficeId;
                    this.profilePhotoId = profilePhotoId;
                } catch (err) {
                    //this.$toasted.error('Could not fetch user active, 2FA, and primary office information.');
                }
            },
            async update(name, field, value) {
                try {
                    await this.$api.put(users.updateUser(this.userId), { [field]: value });
                    this.$toasted.success(`Successfully updated ${name}.`);
                    return true;
                } catch (err) {
                    //this.$toasted.error(`Failed to update ${name}. Please try again later.`);
                    return false;
                }
            },
            async updateActive(newState) {
                const updateSuccess = await this.update('User Status', 'active', newState);
                if (updateSuccess) {
                    this.active = newState;
                }
            },
            async update2fa(newState) {
                const updateSuccess = await this.update('2FA Setting', 'use_2fa', newState);
                if (updateSuccess) {
                    this.use2fa = newState;
                }
            },
            async updatePrimaryOffice(newState) {
                const updateSuccess = await this.update('Primary Office', 'office_id', newState);
                if (updateSuccess) {
                    this.primaryOfficeId = newState;
                }
            },
            async resetPassword(closeModal) {
                const errStr = 'Failed to send password reset email. Please try again later.';

                try {
                    const res = await this.$api.post(auth.resetPassword(), {
                        userId: this.userId,
                    });

                    if (res.status >= 400) {
                        //this.$toasted.error(errStr);
                        return;
                    }

                    this.$toasted.success('Password reset email sent.');
                } catch (err) {
                    //this.$toasted.error(errStr);
                } finally {
                    closeModal();
                }
            },
            async deleteUser() {
                const errStr = 'Failed to delete user. Please try again later.';

                try {
                    const res = await this.$api.put(users.updateUser(this.userId), { deleted: true });

                    if (res.status >= 400) {
                        //this.$toasted.error(errStr);
                        return;
                    }

                    this.$toasted.success('User deleted successfully');
                    await this.$router.replace({ name: 'Dashboard' });
                } catch (err) {
                    //this.$toasted.error(errStr);
                } finally {
                    this.deleteModal = false;
                    // closeModal();
                }
            },
            async handleNewUpload(response) {
                if (!response || !response.fileId) return;

                this.profilePhotoId = response?.fileId;

                this.$store.commit('updateUserDetails', { 
                    userId: this.userId,
                    updatedDetails: {
                        profile_photo_file_id: response?.fileId
                    }
                });
            },
        },
        async mounted() {
            await this.init();
        },
    };
</script>
